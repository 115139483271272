import { Checkbox } from "@mui/material"
import React, { useEffect, useState } from "react"

export const CheckboxCell = ({
  name,
  initialValue,
  onChangeAsync,
  disabled
}) => {
  const [value, setValue] = useState(initialValue)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    let mounted = true
    if (mounted) setValue(initialValue)

    return () => {
      mounted = false
    }
  }, [initialValue])

  const handleUpdate = (newValue) => {
    setValue(newValue)
    setLoading(true)

    onChangeAsync(newValue)
      .catch(() => {
        setValue(initialValue)
      })
      .finally(() => setLoading(false))
  }

  return (
    <Checkbox
      name={name}
      disabled={loading || disabled}
      checked={value}
      onChange={(e) => handleUpdate(e.target.checked)}
    />
  )
}
