import {
  useMutation,
  useQuery,
  useQueryClient,
  useInfiniteQuery
} from "@tanstack/react-query"

import client from "utils/client"
import { getNextPageParam } from "shared/lib/ransack"
import { DEFAULT_STALE_TIMES } from "shared/config"
import { createGetNextPageParam } from "shared/lib/getFiltered"

export const fetchSelectedProductsWebshopPrices = async ({
  ids,
  customerId,
  agencyId,
  withoutAgencyProducts
}) => {
  const res = await client.get("/web_shop_prices/paged.json", {
    params: {
      ids,
      affiliate_products: true,
      without_agency_products: withoutAgencyProducts,
      web_shop: true,
      sort: {
        selected_product_ids: ids
      },
      additional_search: {
        affiliate_type: "JapCustomer",
        affiliate_id: customerId,
        agency_id: agencyId,
        marked_ids: ids
      }
    }
  })

  return res.data
}

export const useWebshopPricesCount = (params, config) =>
  useQuery({
    queryKey: ["web_shop_prices", "count", { ...params, page: 1 }],
    queryFn: async () => {
      const res = await client.get("/web_shop_prices/count.json", {
        params: { ...params, page: 1 }
      })

      return res.data
    },
    staleTime: 5 * 60 * 1000,
    cacheTime: 5 * 60 * 1000,
    ...config
  })

export const useWebshopPrices = (params, config = {}) => {
  const countQuery = useWebshopPricesCount(params, config)

  return useInfiniteQuery({
    queryKey: ["web_shop_prices", "paged", params],
    queryFn: async ({ pageParam = 1 }) => {
      const res = await client.get("/web_shop_prices/paged.json", {
        params: { ...params, page: pageParam }
      })

      return res.data
    },
    keepPreviousData: true,
    getNextPageParam: createGetNextPageParam(countQuery),
    staleTime: 5 * 60 * 1000,
    cacheTime: 5 * 60 * 1000,
    ...config
  })
}

export const useSelectedProductsWebshopPrices = (
  { ids, customerId, agencyId, promotionIds },
  params = {},
  config = {}
) => {
  return useQuery({
    queryKey: ["web_shop_prices", "paged", customerId, ids, params],
    queryFn: async () => {
      const res = await client.get("/web_shop_prices/paged.json", {
        params: {
          ids,
          affiliate_products: true,
          web_shop: true,
          promotion_ids: promotionIds,
          sort: {
            selected_product_ids: ids
          },
          ...params,
          additional_search: {
            affiliate_type: "JapCustomer",
            affiliate_id: customerId,
            agency_id: agencyId,
            marked_ids: ids,
            ...params.additional_search
          }
        }
      })

      return res.data
    },
    keepPreviousData: true,
    staleTime: 5 * 60 * 1000,
    cacheTime: 5 * 60 * 1000,
    enabled: ids.length > 0,
    ...config
  })
}

/**
 * @param {number} id sales product id
 */
export const useSalesProduct = (id) =>
  useQuery(
    ["salesProducts", id],
    async () => {
      const res = await client(`/sales_products/${id}.json`)
      return res.data
    },
    {
      enabled: !!id
    }
  )

/**
 * @return {import("@tanstack/react-query").UseMutationResult}
 */
export const useCreateSalesProduct = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async ({ values }) => {
      const response = await client.post("/v2/sales_products.json", {
        sales_product: values
      })

      return response.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["salesProducts"])
        queryClient.invalidateQueries(["suppliers"])
      }
    }
  )
}

/**
 * @return {import("@tanstack/react-query").UseMutationResult}
 */
export const useUpdateSalesProduct = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async ({ id, values }) => {
      const response = await client.put(`/v2/sales_products/${id}.json`, {
        sales_product: values
      })

      return response.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["salesProducts"])
        queryClient.invalidateQueries(["suppliers"])
        queryClient.invalidateQueries([
          "partner_manager",
          "partner_pricing",
          "product_prices",
          "index"
        ])
      }
    }
  )
}

/**
 * @return {import("@tanstack/react-query").UseMutationResult<any, unknown, number, unknown>}
 */
export const useDeleteSalesProduct = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async (id) => {
      const response = await client.delete(`/sales_products/${id}.json`)
      return response.data
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(["salesProducts"])
        queryClient.invalidateQueries(["suppliers"])
      }
    }
  )
}

export const usePromotions = (params = {}) => {
  return useInfiniteQuery({
    queryKey: ["promotions", "index", params],
    queryFn: async ({ pageParam = 1 }) => {
      const res = await client.get("/promotions.json", {
        params: { ...params, page: pageParam }
      })

      return res.data
    },
    getNextPageParam,
    useErrorBoundary: true
  })
}

export const useContracts = (params) => {
  return useQuery({
    queryKey: ["statistics", "contracts", params],
    queryFn: async () => {
      const res = await client.get("/statistics/contracts.json", { params })

      return res.data
    },
    staleTime: DEFAULT_STALE_TIMES.statistics,
    useErrorBoundary: true
  })
}
