import makeStyles from "@mui/styles/makeStyles"
import times from "lodash/times"
import Drawer from "@mui/material/Drawer"
import Box from "@mui/material/Box"
import Design from "components/AdEditor/Design"
import React, { useCallback } from "react"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import IconButton from "@mui/material/IconButton"
import { useSelector, useDispatch } from "react-redux"
import {
  getAdEditorLayoutPanelState,
  updateAdEditorLayoutPanelState
} from "store/modules/common/auth"

const width = ({ open }) => (open ? 194 : 50)
const height = ({ fullHeight }) =>
  fullHeight ? "100vh" : "calc(100vh - 180px)"

const drawerStyles = makeStyles((theme) => ({
  root: {
    zIndex: 1,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    width,
    marginRight: 20
  },
  paper: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    width,
    height,
    padding: "6px 7px 0 7px",
    position: "sticky"
  }
}))

const useStyles = makeStyles({
  drawerShift: {
    width: 20
  },
  title: {
    fontSize: 16,
    fontFamily: "Roboto Slab",
    fontWeight: "bold",
    opacity: 0.5
  },
  list: {
    paddingTop: 15,
    paddingRight: 15,
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none"
    }
  },
  collapseButton: {
    opacity: 0.5
  }
})

const DesignDrawer = ({ onChange, value, fullHeight }) => {
  const classes = useStyles()
  const open = useSelector(getAdEditorLayoutPanelState)
  const dispatch = useDispatch()
  const drawerClasses = drawerStyles({ open, fullHeight })
  const toggleOpen = useCallback(() => {
    dispatch(updateAdEditorLayoutPanelState(!open))
  }, [dispatch, open])

  return (
    <Drawer variant="permanent" classes={drawerClasses}>
      {open && (
        <React.Fragment>
          <Box display="flex" justifyContent="space-between" marginRight="6px">
            <div className={classes.title}>Layout</div>
            <IconButton
              onClick={toggleOpen}
              size="small"
              id="collapse-design-drawer"
            >
              <ChevronLeftIcon className={classes.collapseButton} />
            </IconButton>
          </Box>
          <Box className={classes.list}>
            {times(9).map((i) => {
              const val = `layout${i + 1}`
              return (
                <Design
                  key={val}
                  onClick={() => onChange(val)}
                  selected={val === value}
                  index={i + 1}
                />
              )
            })}
          </Box>
        </React.Fragment>
      )}
      {!open && (
        <Box textAlign="center">
          <IconButton
            onClick={toggleOpen}
            size="small"
            id="expand-design-drawer"
          >
            <ChevronRightIcon className={classes.collapseButton} />
          </IconButton>
        </Box>
      )}
    </Drawer>
  )
}

export default DesignDrawer
